import React, { useContext, useState } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import H1 from "../components/htmlElements/h1";
import Layout from "../components/layout";
import UserPool from "../components/auth/UserPool";
import InputWrapper from "../components/submitElements/inputWrapper";
import Button from "../components/htmlElements/button";

import { AuthenticationDispatch } from "../context/AuthenticationContext";

const defaultErrorMessage =
  "Sorry, there seems to be an error logging you in, please check your details and try again.";

export default function LoginPage() {
  const [status, setStatus] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const authenticationDispatch = useContext(AuthenticationDispatch) || "";

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: emailAddress,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: emailAddress,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        setErrorMessage("");
        setStatus(
          "You have logged in successfully! You are being redirected now."
        );

        // Get token and email...
        const idToken = data.getIdToken().getJwtToken();
        const { email } = data.getIdToken().payload;

        // Save user and token to context/session
        if (idToken && authenticationDispatch) {
          authenticationDispatch({
            type: "ADD_TOKEN",
            token: idToken,
            username: email,
          });
        }

        // Redirect the user
        navigate("/user/overview");
      },

      onFailure: (err) => {
        setStatus("");
        setErrorMessage(defaultErrorMessage);
      },

      newPasswordRequired: (data) => {
        console.log("new password required", data);
      },
    });
  };

  const displayError = errorMessage ? (
    <div className="mt-4 border border-red/50 bg-red/10 p-1 pl-2 mb-3 text-sm rounded">
      <p>{errorMessage}</p>
    </div>
  ) : (
    ""
  );

  const query = useStaticQuery(graphql`
    query {
      loginPageContentQuery: allMarkdownRemark(
        filter: {
          frontmatter: { title: { glob: "Login to your IRIS account" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const loginPageContent = query.loginPageContentQuery.edges[0].node.html;

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1
            innerContent="Login to your IRIS account"
            additionalClasses="pb-5"
          />

          {displayError}

          {status && status !== "" ? (
            <p>{status}</p>
          ) : (
            <>
              <div
                className="markdownText text-sm"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: loginPageContent,
                }}
              />

              <form className="mt-5">
                <InputWrapper title="Username">
                  <input
                    type="email"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    placeholder="john@doe.com"
                    required
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    autoComplete="on"
                  />
                </InputWrapper>

                <InputWrapper title="Password">
                  <input
                    type="password"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    placeholder="*******"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputWrapper>

                <p className="text-sm text-right">
                  <Link className="underline" to="/login/resetpassword">
                    Forgot your password?
                  </Link>
                </p>

                <Button
                  innerContent="Login"
                  color="blue"
                  type="submit"
                  onClick={handleSubmit}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
